import React, { useState } from "react";
import { Grid, Button, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container, Paper } from "@mui/material";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import { Form, useForm } from "../../../components/mui/useForm";
import { MuiInput } from "../../../components";
import LoadingButton from "@mui/lab/LoadingButton";
import * as authService from "../services/AuthService";
import Notification from "../../../components/mui/Notification";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../../helper/Constants";
import { isPasswordValid } from ".";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20,
    margin: "20px auto",
  },
  btn: {
    margin: "8px 0",
  },
}));

const initialFValues = {
  firstname: "",
  lastname: "",
  username: "",
  password: "",
  role: "STUDENT",
};

export default function Signup(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("firstname" in fieldValues)
      temp.firstname = fieldValues.firstname ? "" : "This field is required.";
    if ("lastname" in fieldValues)
      temp.lastname = fieldValues.lastname ? "" : "This field is required.";
    if ("username" in fieldValues)
      temp.username =
        fieldValues.username !== "" && /$^|.+@.+..+/.test(fieldValues.username)
          ? ""
          : "Email is not valid.";
    if ("password" in fieldValues) {
      if (fieldValues.password) {
        if (!isPasswordValid(fieldValues.password)) {
          temp.password =
            "Must be at least 8 characters with at least one number.";
        } else temp.password = "";
      } else {
        temp.password = "This field is required.";
      }
    }
    // temp.password = fieldValues.password ? "" :
    if ("passwordConfirmation" in fieldValues)
      temp.passwordConfirmation =
        fieldValues.passwordConfirmation === values.password
          ? ""
          : "Passwords not match.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      delete values.passwordConfirmation;
      authService
        .signup(values)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: "We sent you a verification email",
            type: "success",
          });
          setTimeout(() => {
            // Add your logic for the transition
            navigate(LOGIN_ROUTE);
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 409) {
              setNotify({
                isOpen: true,
                message: "Username already exists",
                type: "error",
              });
            } else {
              setNotify({
                isOpen: true,
                message: "Something went wrong. Please try again later.",
                type: "error",
              });
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            setNotify({
              isOpen: true,
              message: "Could not connect to server. Please try again lter",
              type: "error",
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(error.config);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
      <Form onSubmit={handleSubmit}>
        <Paper elevation={10} className={classes.paper}>
          <Grid container justifyContent="center">
            <Grid item align="center">
              <Avatar sx={{ bgcolor: "#1bbd7e" }}>
                <AppRegistrationOutlinedIcon />
              </Avatar>
              <h2>Sign up</h2>
              <MuiInput
                name="firstname"
                label="First name"
                value={values.firstname}
                onChange={handleInputChange}
                error={errors.firstname}
              />
              <MuiInput
                name="lastname"
                label="Last name"
                value={values.lastname}
                onChange={handleInputChange}
                error={errors.lastname}
              />
              <MuiInput
                name="username"
                label="Email address"
                value={values.username}
                onChange={handleInputChange}
                error={errors.username}
              />
              <MuiInput
                name="password"
                label="Password"
                type="password"
                value={values.password}
                onChange={handleInputChange}
                error={errors.password}
              />
              <MuiInput
                name="passwordConfirmation"
                label="Confirm Password"
                type="password"
                value={values.passwordConfirmation}
                onChange={handleInputChange}
                error={errors.passwordConfirmation}
              />
            </Grid>
            <Grid item lg={9.5} xs={9} sm={9} md={9}>
              <LoadingButton
                type="submit"
                sx={{ mt: 1 }}
                color="success"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid
              item
              lg={9.5}
              xs={9}
              sm={9}
              md={9}
              sx={{ mt: "1em", mb: "2em" }}
            >
              <Button variant="text" onClick={() => navigate("/login")}>
                Login
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </Container>
  );
}
