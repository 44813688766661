import React from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reducer from "./domain/education/reducers/reducer";
import initialState from "./domain/education/reducers/initials";
import ResponsiveLayout from "./components/ResponsiveLayout";
import { CaseStudyPurchaseByState } from "./casestudy";
import {
  AccountVerified,
  ForgotPassword,
  Login,
  ResetPassword,
  Signup,
  UserProfile,
} from "./domain/education/user";
import { LandingPage } from "./pages/landing";
import {
  ACCOUNT_ACTIVATED_ROUTE,
  FORGOT_PASS_ROUTE,
  LOGIN_ROUTE,
  PROFILE_ROUTE,
  RESET_PASS_ROUTE,
  SINGUP_ROUTE,
  STUDENT_BOARD_ROUTE,
  CASE_STUDY_PURCHASE_ROUTE,
  CASE_STUDY_PURCHASE_COMPLETION_ROUTE,
  CASE_STUDY_PURCHASE_CHECKOUT_ROUTE,
  STUDENT_ASSIGNMENT_GROUPS_ROUTE,
  CASE_STUDY_ASSIGNMENT_ANSWER,
  STUDENT_ASSIGNMENTS_ROUTE,
} from "./helper/Constants";
import { StudentBoard } from "./domain/education/student/board";
import {
  CaseStudyCheckoutPage,
  CaseStudyPurchaseCompleted,
} from "./domain/education/stripe";
import { AssignmentGroups, AssignmentList } from "./domain/education/student/assignment";
import NotFound from "./common/page/NotFound";
import { AnswerForm } from "./pages/answer";

export const AppContext = React.createContext();

let theme = createTheme({
  palette: {
    primary: {
      main: "#0B72B9",
      light: "#3c44b126",
    },
    secondary: {
      main: "#f83245",
      light: "#f8324526",
    },
    background: {
      default: "#f4f5fd",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: "translateZ(0)",
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    },
  },
});
theme = responsiveFontSizes(theme);

function App() {
  const [appState, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AppContext.Provider
      value={{
        appState,
        dispatch,
      }}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ResponsiveLayout>
            <Routes>
              <Route exact path="/" element={<LandingPage />} />
              <Route
                exact
                path={STUDENT_BOARD_ROUTE}
                element={<StudentBoard />}
              />
              <Route
                exact
                path={STUDENT_ASSIGNMENTS_ROUTE}
                element={<AssignmentList />}
              />
              <Route
                exact
                path={STUDENT_ASSIGNMENT_GROUPS_ROUTE}
                element={<AssignmentGroups />}
              />
              <Route
                exact
                path={CASE_STUDY_ASSIGNMENT_ANSWER}
                element={<AnswerForm />}
              />
              <Route exact path={LOGIN_ROUTE} element={<Login />} />
              <Route exact path={SINGUP_ROUTE} element={<Signup />} />
              <Route
                exact
                path={ACCOUNT_ACTIVATED_ROUTE}
                element={<AccountVerified />}
              />
              <Route exact path={PROFILE_ROUTE} element={<UserProfile />} />
              <Route
                exact
                path={FORGOT_PASS_ROUTE}
                element={<ForgotPassword />}
              />
              <Route
                exact
                path={RESET_PASS_ROUTE}
                element={<ResetPassword />}
              />

              <Route
                exact
                path={CASE_STUDY_PURCHASE_ROUTE}
                element={<CaseStudyPurchaseByState />}
              />
              <Route
                exact
                path={CASE_STUDY_PURCHASE_CHECKOUT_ROUTE}
                element={<CaseStudyCheckoutPage />}
              />
              <Route
                exact
                path={CASE_STUDY_PURCHASE_COMPLETION_ROUTE}
                element={<CaseStudyPurchaseCompleted />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ResponsiveLayout>
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
