import { STUDENT_BOARD_ROUTE } from "../../../helper/Constants"

const cards = [
  {
    title: "Get started",
    description: "Are you a Student looking for a Case Study?",
    imageUrl: "/resources/cs-student.png",
    route: STUDENT_BOARD_ROUTE,
    time: 1500,
    buttonText: "Get Started",
    buttonVariant: "contained",
  },
];

export default cards;
