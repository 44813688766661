import React, { useState } from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
  FormControl,
  InputAdornment,
  OutlinedInput,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import Notification from "../components/mui/Notification";
import { MuiButton } from "../components";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { isEpmty } from "../helper/TextHelper";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { keyframes } from "@emotion/react";
import { styled } from "@mui/material/styles";

// Define keyframes for the border animation
const borderAnimation = keyframes`
  0% { border-color: transparent; }
  50% { border-color: #00FF00; } /* Green color */
  100% { border-color: transparent; }
`;

const AnimatedButton = styled(MuiButton)(({ theme, animate }) => ({
  border: "2px solid transparent",
  transition: "border-color 0.4s ease",
  ...(animate && {
    animation: `${borderAnimation} 1s ease-out`,
  }),
}));

const PurchaseCard = ({ caseStudy, handleCheckout, applyPromotion }) => {
  const navigate = useNavigate();
  const [animate, setAnimate] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [promotionCode, setPromotionCode] = useState();
  const [promoCodeValidationMessage, setPromoCodeValidationMessage] =
    useState();
  const [applying, setApplying] = useState(false);
  const [isApplied, setApplied] = useState(false);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const insertSpacesBetweenWords = (string) => {
    return string.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  const renderListItems = (data) => {
    return Object.entries(data).map(([key, value]) => {
      if (excludedItems.includes(key)) {
        return null; // Exclude this item from rendering
      }
      const capitalizedKey = capitalizeFirstLetter(key);
      const formattedKey = insertSpacesBetweenWords(capitalizedKey);
      return (
        <ListItem key={key}>
          <ListItemText
            primary={
              <>
                <Typography variant="body1">{formattedKey}</Typography>
                {key === "price" && (
                  <Typography variant="caption" color="textSecondary">
                    {"(Tax included)"}
                  </Typography>
                )}
              </>
            }
            sx={{ mr: 2 }}
          />
          <Typography variant="body1" fontWeight="medium">
            {value}
          </Typography>
        </ListItem>
      );
    });
  };

  const handlePromotionCodeChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    if (value.length <= 5) {
      setPromoCodeValidationMessage(undefined);
      setPromotionCode(value);
    }
  };

  const handlePromoApply = (code) => {
    if (promotionCode === caseStudy.promoCode) {
      setApplying(true);
      applyPromotion(caseStudy.promoAmount);
      setPromotionCode("");
      setTimeout(() => {
        setApplying(false);
        setApplied(true);
        setAnimate(true);
      }, 1000);
      setTimeout(() => setAnimate(false), 2000);
    } else {
      setPromoCodeValidationMessage("Not a valid code!");
    }
  };

  const excludedItems = [
    "id",
    "currencyCode",
    "currencyName",
    "promoCode",
    "promoAmount",
  ];

  return (
    <>
      <List sx={{ width: "100%", bgcolor: "background.paper" }} disablePadding>
        {renderListItems(caseStudy)}
        {caseStudy.promoCode && (
          <ListItem dense>
            <FormControl sx={{ m: 0 }} fullWidth>
              <FormLabel htmlFor="standard-adornment-promo">
                Promotion Code
              </FormLabel>
              <OutlinedInput
                error={promoCodeValidationMessage}
                size="small"
                variant="outlined"
                id="standard-adornment-promo"
                value={promotionCode}
                onChange={handlePromotionCodeChange}
                disabled={isApplied}
                endAdornment={
                  <InputAdornment position="end">
                    <LoadingButton
                      size="small"
                      onClick={() => handlePromoApply(promotionCode)}
                      loading={applying}
                      // loadingPosition="start"
                      disabled={isApplied || isEpmty(promotionCode)}
                      startIcon={isApplied && <DoneRoundedIcon />}
                    >
                      <span>Apply</span>
                    </LoadingButton>
                  </InputAdornment>
                }
              />
              {promoCodeValidationMessage && (
                <FormHelperText sx={{ color: "red" }}>
                  {promoCodeValidationMessage}
                </FormHelperText>
              )}
            </FormControl>
          </ListItem>
        )}
      </List>
      <Grid item xs={12} sx={{ mt: 2 }} align="center">
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction={{ xs: "column", sm: "row" }}
          sx={{ ml: 2 }}
        >
          <AnimatedButton
            sx={{ border: "1px solid" }}
            text={"Pay " + caseStudy.price + " " + caseStudy.currencyName}
            color={isApplied ? "success" : "info"}
            variant="outlined"
            onClick={handleCheckout}
            animate={animate ? 1 : 0}
          />
          <MuiButton
            text={"Not Now"}
            color="inherit"
            variant="outlined"
            onClick={() => navigate(-1)} // Navigate back to the previous page
          />
        </Stack>
      </Grid>

      <Notification
        notify={notify}
        setNotify={setNotify}
        autoHideDuration={4000}
      />
    </>
  );
};

export default PurchaseCard;
