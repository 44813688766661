import { httpClient } from "./RestService";

export const getAll = async () => {
  return await httpClient().get("/v1/education/st/assmnt/list");
};

export const searchById = async (id) => {
  return await httpClient().get("/v1/education/st/assmnt/search/" + id);
};

export const enroll = async (assignmentId) => {
  return await httpClient().post("/v1/education/st/assmnt/enroll", {
    id: assignmentId,
  });
};

export const create = async (data) => {
  return await httpClient().post("/v1/education/st/assmnt/grp", data);
};

export const del = async (id) => {
  return await httpClient().delete("/v1/education/st/assmnt/" + id);
};

export const join = async (groupId) => {
  return await httpClient().post("/v1/education/st/assmnt/grp/join", {
    groupId,
  });
};

export const leave = async (groupId) => {
  return await httpClient().delete(
    "/v1/education/st/assmnt/grp/leave/" + groupId
  );
};

export const getAllGroups = async (assignmentId) => {
  return await httpClient().get(
    "/v1/education/st/assmnt/grp?assignmentId=" + assignmentId
  );
};

export const getAllQuestions = async (caseStudyId) => {
  return await httpClient().get(
    "/v1/education/st/assmnt/qs?caseStudyId=" + caseStudyId
  );
};

export const submitAnswer = async (data) => {
  return await httpClient().post("/v1/education/st/assmnt/grp/ans", data);
};
