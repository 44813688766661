import React, { useState, useEffect } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { MuiInput, MuiButton } from "../../components";
import { Form } from "../../components/mui/useForm";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "../../components/mui/Notification";
import LoadingButton from "@mui/lab/LoadingButton";
import PageHeader from "../../components/PageHeader";
import * as assignmentService from "../../domain/education/services/AssignmentService";
import ConfirmDialog from "../../components/ConfirmDialog";
import { Submitted } from "./Submitted";
import LoadingSpinner from "../../components/LoadingSpinner";
import { isEpmty } from "../../helper/TextHelper";

export default function AnswerForm(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState();
  const [submitted, setSubmitted] = useState();
  const [questions, setQuestions] = useState([]);
  const [questionError, setQuestionError] = useState({});
  const group = state.group;
  const groupId = group.id;
  const caseStudyId = state.caseStudyId;
  const assignmentId = state.assignmentId;

  useEffect(() => {
    assignmentService
      .getAllQuestions(caseStudyId)
      .then((response) => {
        setQuestions(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validate = () => {
    const newQuestionErrors = {};

    questions.forEach((question) => {
      if (isEpmty(question.answer))
        newQuestionErrors[question.id] = `This field is required.`;
    });

    // Update the error state with any found errors
    setQuestionError(newQuestionErrors);

    // Return true if no errors, otherwise false
    return Object.keys(newQuestionErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure to submit the answer?",
        subTitle: "This action is irreversible. Please confirm the members.",
        addons: (
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {group.members.map((member, index) => (
              <React.Fragment key={member.id}>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt={member.firstName} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={member.firstName + " " + member.lastName}
                    sx={{ display: "flex", alignItems: "center" }} // Ensure text is centered vertically
                  />
                </ListItem>
                {index < group.members.length - 1 && (
                  <Divider variant="inset" component="li" />
                )}
              </React.Fragment>
            ))}
          </List>
        ),
        onConfirm: () => {
          sendAnswer();
        },
      });
    }
  };

  const sendAnswer = () => {
    setSubmitting(true);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    assignmentService
      .submitAnswer({
        questions: questions,
        groupId: groupId,
        caseStudyId: caseStudyId,
        assignmentId: assignmentId,
      })
      .then((response) => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not submit an answer. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setSubmitting(false));
  };

  const inputChangeHandler = (questionId, e) => {
    const { value } = e.target;

    const updatedQuestions = questions.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          answer: value,
        };
      }
      return question;
    });

    setQuestions(updatedQuestions); // Update the state with the new questions
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title="Submit Answer" isChild={true} />
      {!submitted ? (
        loading ? (
          <LoadingSpinner />
        ) : (
          <Form onSubmit={handleSubmit}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              {questions.map((question, qIndex) => (
                <Card
                  key={qIndex}
                  sx={{
                    m: 2,
                  }}
                >
                  <CardHeader
                    title={
                      <Typography variant="h5">
                        {"Question " + (qIndex + 1)}
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Typography sx={{ ml: 2, mb: 2 }}>
                      {question.title}
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} sx={{ mt: 0 }} align="center">
                        <MuiInput
                          label="Enter your answer"
                          name="content"
                          multiline
                          minRows={25}
                          value={question.answer}
                          onChange={(e) => inputChangeHandler(question.id, e)}
                          // error={Boolean(question.answer)}
                          error={questionError[question.id]}
                          // helperText={questionError[question.id] || ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction={{ xs: "column", sm: "row" }}
                sx={{ ml: 1 }}
              >
                <LoadingButton
                  type="submit"
                  color="success"
                  variant="outlined"
                  loading={submitting}
                >
                  Submit
                </LoadingButton>

                <MuiButton
                  text="Cancel"
                  color="info"
                  variant="outlined"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </Stack>
            </Paper>
            <Notification notify={notify} setNotify={setNotify} />
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Form>
        )
      ) : (
        <Submitted />
      )}
    </Container>
  );
}
