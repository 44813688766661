import React, { useState, useEffect, useContext } from "react";
import { Container, Grid, Skeleton, useMediaQuery } from "@mui/material";
import * as casestudyService from "../domain/education/services/CasestudyService";
import Notification from "../components/mui/Notification";
import PageHeader from "../components/PageHeader";
import PurchaseCard from "./PurchaseCard";
import { AppContext } from "../App";
import useRequireLogin from "../common/hook/useRequireLogin";
import { LOGIN_ROUTE, STUDENT_BOARD_ROUTE, CASE_STUDY_PURCHASE_CHECKOUT_ROUTE } from "../helper/Constants";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";

export default function PurchaseByState() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { caseStudyId, assignmentId } = state;
  const { appState } = useContext(AppContext);
  const [readyToLoad, setReadyToLoad] = useState(false);
  useRequireLogin(appState, LOGIN_ROUTE, STUDENT_BOARD_ROUTE, () =>
    setReadyToLoad(true)
  );
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(true);
  const [caseStudy, setCaseStudy] = useState();
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm")); // Adjust the breakpoint as needed

  useEffect(() => {
    if (readyToLoad) {
      if (!caseStudyId) {
        console.error(`Could not find Case study Id`);
        setNotify({
          isOpen: true,
          message: "Could not get Case study Id. Please try again.",
          type: "error",
        });
      } else {
        casestudyService
          .getPurchaseDetails(caseStudyId, assignmentId)
          .then((response) => {
            setCaseStudy(response.data);
            setLoading(false);
          })
          .catch((e) => {
            console.error(`Error: ${e.message}`);
            if (e.response && e.response.status === 404) {
              setNotify({
                isOpen: true,
                message: "Could not find purchase details.",
                type: "warning",
              });
            } else {
              setNotify({
                isOpen: true,
                message: "Could not connect to server. Please try again later.",
                type: "error",
              });
            }
            setTimeout(() => {
              // Add your logic for the transition
              navigate(STUDENT_BOARD_ROUTE, { replace: true });
            }, 2000);
          });
      }
    }
  }, [readyToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckout = () => {
    navigate(CASE_STUDY_PURCHASE_CHECKOUT_ROUTE, {
      state: {
        caseStudyId,
        amount: caseStudy.price,
        currency: caseStudy.currencyCode,
      },
    });
  };

  const applyPromotion = (amount) => {
    const discount = (caseStudy.price * amount) / 100;
    const newPrice = caseStudy.price - discount.toFixed(2);
    setCaseStudy((prevCasestudy) => ({
      ...prevCasestudy,
      price: newPrice,
      promoCode: undefined,
    }));
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title={"Case Study Details"} isChild={true} />
      <Grid container spacing={2} justifyContent="center">
        {loading ? (
          Array.from(new Array(1)).map((item, index) => (
            <Grid item key={index} xs={12} sm={6} sx={{ mt: 2 }}>
              <Skeleton variant="rectangular" height={118} />
              <Skeleton />
              <Skeleton />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={isSmOrDown ? 10 : 6} md={4}>
            <PurchaseCard
              caseStudy={caseStudy}
              handleCheckout={() => handleCheckout()}
              applyPromotion={(amount) => applyPromotion(amount)}
            />
          </Grid>
        )}
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </Container>
  );
}
