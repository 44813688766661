import React, { useContext } from "react";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { upperCaseFirstLetter } from "../../../../../helper/TextHelper";
import {
  Avatar,
  CardContent,
  Card,
  CardActions,
  Tooltip,
  Box,
} from "@mui/material";
import { MuiButton } from "../../../../../components";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { AppContext } from "../../../../../App";
import GroupsIcon from "@mui/icons-material/Groups";
import DoneAllIcon from "@mui/icons-material/DoneAll";

export default function GroupCard({
  group,
  handleJoin,
  handleLeave,
  handleProjectSubmit,
}) {
  const { appState } = useContext(AppContext);
  const userId = appState.userInfo?.userId;

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    // Handle null or undefined names by providing a default value
    const defaultName = "Unknown";
    const [firstName = "", lastName = ""] = (name || defaultName).split(" ");

    // Use the first letter of the first name and last name, or fallback to a default letter if either is missing
    const initials = `${firstName[0] || ""}${lastName[0] || ""}`.toUpperCase();

    return {
      sx: {
        bgcolor: stringToColor(name || defaultName),
      },
      children: initials,
    };
  }

  function isUserInGroup() {
    return group.members.some((member) => member.userId === userId);
  }

  return (
    <Card
      elevation={5}
      sx={{
        m: 0.5,
        border: "1px solid",
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#2196f3" }}>
            <GroupsIcon />
          </Avatar>
        }
        action={
          !group.hasSubmittedAnswer ? (
            isUserInGroup() ? (
              <IconButton
                color="error"
                onClick={() => handleLeave(group.id, userId)}
              >
                <PersonRemoveIcon />
              </IconButton>
            ) : (
              <IconButton color="success" onClick={() => handleJoin(group.id)}>
                <PersonAddIcon />
              </IconButton>
            )
          ) : (
            <IconButton color="success">
              <DoneAllIcon />
            </IconButton>
          )
        }
        title={upperCaseFirstLetter(group.name)}
      />
      <CardContent sx={{ m: 1 }}>
        {/* , flexWrap: "wrap"*/}
        <Box sx={{ overflowX: "auto", display: "flex", flexWrap: "wrap" }}>
          {group.members.map((member, i) => (
            <Box key={i} sx={{ m: 0 }}>
              <Tooltip title={member.firstName + " " + member.lastName}>
                {/* <IconButton
                  onClick={() => handleAvatarClick(`Mohammad Hosnidokht ${i}`)}
                  size="small"
                > */}
                <Avatar
                  {...stringAvatar(
                    (member.firstName || "") + " " + (member.lastName || "")
                  )}
                />
                {/* </IconButton> */}
              </Tooltip>
            </Box>
          ))}
        </Box>
      </CardContent>
      {!group.hasSubmittedAnswer && isUserInGroup() && (
        <CardActions>
          <MuiButton
            size="small"
            text="Submit Answer"
            variant="text"
            sx={{ ml: 1 }}
            onClick={handleProjectSubmit}
          />
        </CardActions>
      )}
    </Card>
  );
}
