import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { upperCaseFirstLetter } from "../../../../helper/TextHelper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as assignmentService from "../../services/AssignmentService";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress, InputAdornment } from "@mui/material";
import Notification from "../../../../components/mui/Notification";

export default function SearchBar({ onSelect, ...props }) {
  const [value] = useState(null);
  const [options, setOptions] = useState([]);
  const [searching, setSearching] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [noOptionsText, setNoOptionsText] = useState(
    "No Projects. Please enter a valid code"
  );

  const handleSearch = (assignmentId) => {
    const isInList = options.some(
      (existingOption) => existingOption.id === assignmentId
    );

    if (!isInList) {
      setSearching(true);
      assignmentService
        .searchById(assignmentId)
        .then((response) => {
          // Check if response.data exists and has any data
          if (response.data && Object.keys(response.data).length > 0) {
            // setOptions((existingOptions) => [
            //   response.data,
            //   ...existingOptions,
            // ]);
            setOptions([response.data]);
          }
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not connect to server. Please try again later.",
            type: "error",
          });
        })
        .finally(() => setSearching(false));
    }
  };

  const isValidUUID = (uuid) => {
    // Regex to validate UUID (version 4)
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(uuid);
  };

  return (
    <>
      <Autocomplete
        {...props}
        id="search-assignment"
        getOptionLabel={(option) => option.id}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        loading={searching}
        value={value}
        noOptionsText={noOptionsText}
        onChange={(event, newValue) => {
          onSelect(newValue.id);
        }}
        onInputChange={(event, newInputValue) => {
          if (isValidUUID(newInputValue)) {
            setNoOptionsText("No Projects");
            handleSearch(newInputValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search by Project Code"
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <React.Fragment>
                  {searching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;

          return (
            <li key={key} {...optionProps}>
              <Grid container sx={{ alignItems: "center" }}>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  <Typography variant="h6" color="text.secondary">
                    {upperCaseFirstLetter(option.title)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {upperCaseFirstLetter(option.caseStudyTitle)}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {upperCaseFirstLetter(option.course) +
                      " course in " +
                      option.semester}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
