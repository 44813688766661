import React, { useContext, useState } from "react";
import { AssignmentList } from "../assignment";
import useRequireLogin from "../../../../common/hook/useRequireLogin";
import { STUDENT_BOARD_ROUTE, LOGIN_ROUTE } from "../../../../helper/Constants";
import { AppContext } from "../../../../App";

export const Board = () => {
  const { appState } = useContext(AppContext);
  const [readyToLoad, setReadyToLoad] = useState(false);
  useRequireLogin(appState, LOGIN_ROUTE, STUDENT_BOARD_ROUTE, () =>
    setReadyToLoad(true)
  );

  if (readyToLoad) {
    return <AssignmentList />;
  }
};
