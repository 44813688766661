import React, { useEffect, useState, useContext } from 'react'
import { Avatar, Container, Grid, Stack } from '@mui/material'
import { MuiInput, MuiButton, MuiImageUpload } from "../../../components";
import { useForm, Form } from '../../../components/mui/useForm';
import { useNavigate } from 'react-router-dom';
import Notification from '../../../components/mui/Notification';
import * as cloudinaryService from "../services/CloudinaryService";
import * as userService from "../services/UserService";
import LoadingButton from '@mui/lab/LoadingButton';
import { AppContext } from "../../../App";
import persianRex from 'persian-rex'
import { profileUpdated } from "../reducers/actions"
import { LOGIN_ROUTE } from '../../../helper/Constants'

export default function UserProfile(props) {

    const { appState, dispatch } = useContext(AppContext);
    const navigate = useNavigate();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [loading, setLoading] = useState(false);
    const [photo, setPhoto] = useState(appState.userInfo?.photoUrl)
    const [photoPreview, setPhotoPreview] = useState(appState.userInfo?.photoUrl)
    const [ direction, setDirection ] = useState({});

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if (!values.hasOwnProperty('firstName') || 'firstName' in fieldValues)
            temp.firstName = fieldValues.firstName ? "" : "This field is required."
        if (!values.hasOwnProperty('lastName') || 'lastName' in fieldValues)
            temp.lastName = fieldValues.lastName ? "" : "This field is required."
        if (photo === undefined || photo === null) 
            temp.photo = "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const initialFValues = {
      id: appState.userInfo?.id,
      firstName: appState.userInfo?.firstName,
      lastName: appState.userInfo?.lastName,
      photoUrl: appState.userInfo?.photoUrl,
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFValues, true, validate);

    const handleSelectedFile = e => {
        setPhoto(e.target.files[0])
        setPhotoPreview(URL.createObjectURL(e.target.files[0]),)
        errors["photo"] = "";
    }

    useEffect(() => {
        if (!appState.isLoggedIn) {
            navigate(LOGIN_ROUTE)
        }
    }, [appState]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            setLoading(true)
            if (photoPreview !== values.photoUrl) {
                cloudinaryService.uploadProfilePhoto(photo)
                    .then(res => {
                        values['imageUrl'] = res.secure_url
                        values['imagePublicId'] = res.public_id
                        values['imageResourceType'] = res.resource_type
                        update(values);
                    })
                    .catch(e => {
                      setLoading(false)
                      setNotify({
                        isOpen: true,
                        message: e.message,
                        type: 'error'
                     })
                    })
            } else {
                update(values);
            }
        }
    }

    const update = (formData) => {
        const data = Object.assign({}, formData);
        userService.updateProfile(data)
          .then(ignored => {
              dispatch(profileUpdated({firstName: data.firstName, lastName: data.lastName, photoUrl: data.imageUrl || data.photoUrl}))
              setLoading(false)
              setNotify({
                  isOpen: true,
                  message: 'Submitted Successfully',
                  type: 'success'
              })
              if (appState.lastVisitedPage !== '') {
                setTimeout(()=>{
                  // Add your logic for the transition
                  navigate(-1, { replace: true })
             }, 1000);
                
              }
          })
          .catch(e => {
              console.log(e);
              cloudinaryService.deleteImage(data)
                  .then(ignored => {
                      setLoading(false)
                      console.log("Deleted a photo :-(")
                  })
              setNotify({
                  isOpen: true,
                  message: 'Something goes wrong. Please try again later',
                  type: 'error'
              })
          })
    }

    const handleDirection = (name, value) => {
        if (persianRex.rtl.test(value)) {
            setDirection({ ...direction, [name]: 'rtl'})
        } else {
            setDirection({ ...direction, [name]: 'ltr'})
        } 
    }

    const inputChangeHandler = e => {
        const { name, value } = e.target
        handleDirection(name, value)
        handleInputChange(e)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Container maxWidth="md" sx={{border: "1px dashed"}}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} align='center' sx={{mt: 2}}>
                      <Avatar
                          alt="user photo"
                          src={photoPreview || '/resources/upload.png'}
                          sx={{ width: 150, height: 150 }}
                      />
                      <MuiImageUpload 
                          name="photo"
                          label="Choose Photo"
                          value={photo || values.photoUrl}
                          onFileSelected={handleSelectedFile}
                          acceptType="image/*"
                          error={errors.photo}
                          sx={{height: '10px'}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} align='center' >
                        <MuiInput
                            dir={direction?.firstName}
                            name="firstName"
                            label="First name"
                            value={values.firstName || appState.userInfo?.firstName}
                            onChange={inputChangeHandler}
                            error={errors.firstName}
                            size="small"
                        />
                        <MuiInput
                            dir={direction?.lastName}
                            name="lastName"
                            label="Last name"
                            value={values.lastName || appState.userInfo?.lastName}
                            onChange={inputChangeHandler}
                            error={errors.lastName}
                            size="small"
                        />
                        <MuiInput
                            name="username"
                            label="Email/Mobile number"
                            value={appState.userInfo?.username}
                            size="small"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sx={{mt:2, mb: 5}}>
                        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                            <LoadingButton
                                type="submit"
                                color="success"
                                variant="outlined"
                                loading={loading}>
                                Submit
                            </LoadingButton>
                            <MuiButton
                                size="medium"
                                text="Cancel"
                                color="info"
                                variant="outlined"
                                onClick={() => {navigate(-1)}} />
                        </Stack>
                    </Grid>
                </Grid>
                <Notification
                    notify={notify}
                    setNotify={setNotify}
                />
            </Container>
        </Form>
    )
}